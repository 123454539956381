//import { useRef, useState, useMemo, useEffect } from "react";
import { useFrame } from "@react-three/fiber";
//import { MathUtils } from "three";
import { Color } from "three";

const black = new Color("white");

export default function Ritmo({ isPlaying, position, scale }) {
  // const colorTo = useMemo(
  ////// );

  return (
    <mesh position={position} scale={scale}>
      {isPlaying && (
        <>
          <sphereGeometry />
          <meshPhysicalMaterial
            roughness={0}
            metalness={0.0}
            thickness={3.12}
            ior={2.14}
            transmission={1.0}
          />
        </>
      )}
    </mesh>
  );
}
