import { useRef, useState, useMemo, useEffect } from "react";
import { useFrame } from "@react-three/fiber";
import { MathUtils } from "three";
import { Color } from "three";
import { Decal, Text, RenderTexture, useTexture } from "@react-three/drei";

const black = new Color("white");
const soundTracks = [
  "sound1",
  "sound2",
  "sound3",
  "sound4",
  "Lady",
  "sound6",
  "sound7",
  "sound8",
  "sound9",
  "daftpunk",
];

export default function Button({
  idkey,
  onChangeButton,
  active,
  playAudio,
  ...props
}) {
  const ref = useRef();
  const [hovered, setHovered] = useState(false);
  const selected = useMemo(() => active === idkey, [active, idkey]);

  const colorTo = useMemo(
    () => new Color(Math.floor(Math.random() * 16777216)),
    []
  );

  useFrame(() => {
    ref.current.rotation.x = hovered
      ? MathUtils.lerp(ref.current.rotation.x, 0, 0.1002)
      : MathUtils.lerp(ref.current.rotation.x, 0, 0.025);

    ref.current.position.z = selected
      ? MathUtils.lerp(ref.current.position.z, -4, 0.025)
      : MathUtils.lerp(ref.current.position.z, 0, 0.025);

    ref.current.position.z = hovered
      ? MathUtils.lerp(
          ref.current.position.z,
          ref.current.position.z - 1.0,
          0.0411
        )
      : MathUtils.lerp(ref.current.position.z, 0, 0.025);

    ref.current.material.color.lerp(selected ? black : colorTo, 0.025);
  });
  const [decal1, decal2] = useTexture(["./logo.png", "./html.png"]);
  const textRef = useRef();

  return (
    <mesh
      {...props}
      ref={ref}
      onPointerDown={() => {
        onChangeButton(idkey);
      }}
      onPointerOver={() => setHovered(true)}
      onPointerOut={() => setHovered(false)}
    >
      <sphereGeometry />

      <meshPhysicalMaterial
        roughness={0}
        metalness={0.0}
        thickness={3.12}
        ior={2.14}
        transmission={1.0}
      >
        <RenderTexture attach="map" anisotropy={16}>
          <color attach="background" args={["transparent"]} />

          <Text
            ref={textRef}
            fontSize={3}
            color="black"
            textAlign="left"
            position={[-5, 0, 0]}
            outlineWidth={1.5}
            outlineColor="white"
            strokeColor={"black"}
          >
            {selected ? "on" : `${soundTracks[idkey - 1]}`}
          </Text>
        </RenderTexture>
      </meshPhysicalMaterial>
    </mesh>
  );
}
