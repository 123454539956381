import { useRef, useMemo } from "react";
import { useFrame } from "@react-three/fiber";
import { BufferGeometry, Float32BufferAttribute } from "three";

function AudioVisualizer({ audioData }) {
  const bufferGeometry = useMemo(() => {
    const geometry = new BufferGeometry();
    geometry.setAttribute("position", new Float32BufferAttribute([], 3));
    return geometry;
  }, [audioData]);

  useFrame(() => {
    if (audioData) {
      const position = [4, 4, 4];
      const data = audioData || new Array(150).fill(0);
      const stepSize = Math.round(data.length / 150);
      for (let i = 0; i < data.length; i += stepSize) {
        const x = (i / data.length) * 20 - 10;
        const y = data[i] * 10;
        position.push(4, 4, 4);
      }

      console.log("positions", position);
    }
  });

  return (
    <mesh>
      <sphereGeometry position={[1, 1, 4]} />
    </mesh>
  );
}
export default AudioVisualizer;
