import { useState, useRef, useEffect, Suspense } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { Vector3, AudioAnalyser } from "three";
import {
  Stats,
  Environment,
  Center,
  Sphere,
  PositionalAudio,
} from "@react-three/drei";
import { Howl } from "howler";
import * as THREE from "three";
import BallCanvas from "./Button";
import Ritmo from "./Ritmo";
import { useAudioData, visualizeAudio } from "@remotion/media-utils";
import sound1 from "./sounds/sound1.mp3";
import sound2 from "./sounds/sound2.mp3";
import sound3 from "./sounds/sound3.mp3";
import sound4 from "./sounds/sound4.mp3";
import Lady from "./sounds/Lady.mp3";
import sound6 from "./sounds/sound6.mp3";
import sound7 from "./sounds/sound7.mp3";
import sound8 from "./sounds/sound8.mp3";
import sound9 from "./sounds/sound9.mp3";
import daftpunk from "./sounds/daftpunk.mp3";
import silent from "./sounds/silent.mp3";
import { useCurrentFrame } from "remotion";
import AudioVisualizer from "./AudioVisualizer";

const soundTracks = [
  sound1,
  sound2,
  sound3,
  sound4,
  Lady,
  sound6,
  sound7,
  sound8,
  sound9,
  daftpunk,
];

const vec = new Vector3();

function Rig() {
  return useFrame(({ camera, mouse }) => {
    vec.set(mouse.x * 1, mouse.y * 1, camera.position.z);
    camera.position.lerp(vec, 0.005);
    camera.lookAt(0, 0, 0);
  });
}

export default function App() {
  const [active, setActive] = useState(0);
  const currentlyPlaying = useRef(null);
  const isPlaying = currentlyPlaying.current !== null;
  const [src, setSrc] = useState(silent);
  const audioData = useAudioData(src);
  const [scale, setScale] = useState(5);

  useEffect(() => {
    if (
      isPlaying &&
      (currentlyPlaying.current !== null) |
        (currentlyPlaying.current !== silent)
    ) {
      setSrc(currentlyPlaying.current._src);
    } else {
      setSrc(silent);
    }
  }, [isPlaying]);
  const stopAudio = () => {
    if (currentlyPlaying.current) {
      currentlyPlaying.current.stop();
      currentlyPlaying.current = null;
      setActive(null);
    }
  };
  const playAudio = (id) => {
    // Stop and clear the currently playing audio if there is any
    if (currentlyPlaying.current) {
      currentlyPlaying.current.stop();
      currentlyPlaying.current = null;
      stopAudio();
      setActive(12);
    }

    // Start the new sound if the id > 0
    if (id > 0) {
      const sound = new Howl({
        src: soundTracks[id - 1],
        volume: 0.75,
      });
      currentlyPlaying.current = sound;
      sound.play();
    }
  };

  const handleActiveChange = (id) => {
    if (id === active) {
      setActive(null);
      if (currentlyPlaying.current) {
        // Check if there is a currently playing sound
        currentlyPlaying.current.stop(); // Stop the currently playing sound
        currentlyPlaying.current = null; // Clear the reference
      }
    } else {
      if (currentlyPlaying.current) {
        // Check if there is a currently playing sound
        currentlyPlaying.current.stop(); // Stop the currently playing sound
      }
      setActive(id);
      playAudio(id); // Start the new sound
    }
  };

  function Analyzer({ sound }) {
    // <Analyzer /> will not run before everything else in the suspense block is resolved.
    // That means <PositionalAudio/>, which executes async, is ready by the time we're here.
    // The next frame (useEffect) is guaranteed(!) to access positional-audios ref.
    const mesh = useRef();
    const analyser = useRef();
    useEffect(
      () =>
        void (analyser.current = new THREE.AudioAnalyser(sound.current, 32)),
      []
    );
    useFrame(() => {
      if (analyser.current) {
        const data = analyser.current.getAverageFrequency();
        mesh.current.material.color.setRGB(data / 100, 0, 0);
        mesh.current.scale.x =
          mesh.current.scale.y =
          mesh.current.scale.z =
            (data / 100) * 12;
      }
    });
    return (
      <Sphere ref={mesh} position={[+7, +8, -10]}>
        <meshPhysicalMaterial
          roughness={0}
          metalness={0.0}
          thickness={3.12}
          ior={2.14}
          transmission={1.0}
        />
      </Sphere>
    );
  }

  function PlaySound({ url }) {
    // This component creates a suspense block, blocking execution until
    // all async tasks (in this case PositionAudio) have been resolved.
    const sound = useRef();
    return (
      <Suspense fallback={null}>
        <PositionalAudio autoplay url={url} ref={sound} />
        <Analyzer sound={sound} />
      </Suspense>
    );
  }
  return (
    <Canvas camera={{ position: [0, 0, 7] }}>
      <ambientLight />
      <pointLight position={[10, 10, 10]} />
      <Environment preset="forest" background />
      <Center>
        {[...Array(5).keys()].map((x) =>
          [...Array(2).keys()].map((y) => (
            <BallCanvas
              key={x + y * 5}
              idkey={x + 1 + y * 5}
              position={[x * 3, y * 3, 0]}
              active={active}
              onChangeButton={handleActiveChange}
            />
          ))
        )}
        <PlaySound url={src} />
      </Center>
      <Rig />

      <Stats />
    </Canvas>
  );
}
{
  /*  <Ritmo
        position={[0, +6, -7]}
        isPlaying={isPlaying}
        scale={scale}
        audioData={audioData}
      /> */
}
